import axios from "axios";
import { CustomerResponse } from "../../model/customer";

export async function getCustomers(
  page: number,
  search?: string
): Promise<CustomerResponse> {
  const { data } = await axios.get<CustomerResponse>(
    `http://localhost:5282/Customers`,
    {
      params: {
        page: page,
        search: search,
      },
    }
  );

  return data;
}
