import { observer } from "mobx-react";
import { useContext, useEffect, useState } from "react";
import { StoreContext } from "../../stores/customerStore";

const CustomersPage = () => {
  const { getCustomers, isLoading, customers } = useContext(StoreContext)!;

  useEffect(() => {
    loadCustomers();
  }, []);

  const loadCustomers = async () => {
    await getCustomers(1);
  };

  return isLoading ? (
    <span>Loading</span>
  ) : (
    <table>
      <tr>
        <th>Imię i nazwisko</th>
        <th>Numer telefonu</th>
        <th>Zgoda marketingowa</th>
      </tr>
      {customers.map((c) => (
        <tr>
          <td>{c.fullName}</td>
          <td>{c.phoneNumber}</td>
          <td>{c.marketingAgreement}</td>
        </tr>
      ))}
    </table>
  );
};

export default observer(CustomersPage);
