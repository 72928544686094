import { observable, makeObservable, action, runInAction } from "mobx";
import { createContext } from "react";
import { Customer } from "../model/customer";
import { getCustomers } from "../api/customers";

interface ICustomerStore {
  customers: Customer[];
  isLoading: boolean;
  getCustomers: (page: number, search?: string) => Promise<void>;
}

class CustomerStore implements ICustomerStore {
  @observable
  customers: Customer[] = [];

  @observable
  isLoading: boolean = false;

  constructor() {
    makeObservable(this);
  }

  @action
  getCustomers = async (page: number, search?: string) => {
    this.isLoading = true;
    const res = await getCustomers(page, search);

    runInAction(() => {
      this.customers = res.data;
      this.isLoading = false;
    });
  };
}

const customerStoreInstance = new CustomerStore();

export const StoreContext = createContext<ICustomerStore>(
  customerStoreInstance
);
